
<template>
    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <div class="box box-primary">
                <div class="box-header with-border">
                    <h3 class="box-title">Přidat operaci do číselníku</h3>
                </div>
                <form role="form">
                    <div class="box-body" style="padding-left: 0; padding-right: 0">
                        <div class="col-md-2">
                            <label for="select-type" class="control-label">Typ</label>
                            <!-- <Select2 v-model="formResponses.type" :options="['Práce', 'Materiál']" id="select-type"/> -->
                        
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input type="radio" v-model="formResponses.type" class="form-check-input" name="type" value="Práce">
                                        <label class="form-check-label" style="margin-left:4px">Práce</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" v-model="formResponses.type" class="form-check-input" name="type" value="Materiál">
                                        <label class="form-check-label" style="margin-left:4px">Materiál</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-7">
                                <div :class="{'has-error': $v.formResponses.description.$error}">
                                    <label for="inputName" class="control-label" v-if="formResponses.type == 'Práce'">Popis práce</label>
                                    <label for="inputName" class="control-label" v-else>Popis materiálu</label>
                                    <input id="inputName" class="form-control" v-model="$v.formResponses.description.$model" type="text">
                                    <span v-if="!$v.formResponses.description.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.description.maxLength" class="help-block">Může mít maximálně {{$v.formResponses.description.$params.maxLength.max}} znaků!</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-4">
                                <div :class="{'has-error': $v.formResponses.invoice_price.$error}">
                                    <label for="inputName" class="control-label" v-if="formResponses.type == 'Práce'">Fakturovaná cena za 1 hodinu bez DPH</label>
                                    <label for="inputName" class="control-label" v-else>Prodejní cena za jednotku bez DPH</label>
                                    <input id="inputName" class="form-control" v-model="$v.formResponses.invoice_price.$model" type="number">
                                    <span v-if="!$v.formResponses.invoice_price.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.invoice_price.decimal" class="help-block">Musí být číslo!</span>
                                    <span v-if="!$v.formResponses.invoice_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="select-dph" class="control-label">DPH</label>
                                <Select2 v-model="formResponses.dph" :disabled="!this.invoiceInfo.taxed" :options="['21%', '12%', '0%']" id="select-dph"/>
                            </div>
                            <div class="col-md-4">
                                <div :class="{'has-error': $v.formResponses.amount.$error}">
                                    <label for="inputName" class="control-label">{{formResponses.type == "Práce" ? "Doba trvání operace v hod" : "Množství"}}</label>
                                    <input id="inputName" class="form-control" v-model="$v.formResponses.amount.$model" type="number">
                                    <span v-if="!$v.formResponses.amount.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.amount.decimal" class="help-block">Musí být číslo!</span>
                                    <span v-if="!$v.formResponses.amount.minValue" class="help-block">Číslo musí být kladné!</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="formResponses.type == 'Materiál'" class="col-md-12 no-remove">
                            <div :class="{'has-error': $v.formResponses.price.$error}">
                                <label for="inputEmail" class="control-label">Nákupní cena za jednotku bez DPH</label>
                                <input type="text" class="form-control" id="inputEmail" v-model="$v.formResponses.price.$model">
                                <span v-if="!$v.formResponses.price.required" class="help-block">Povinný údaj</span>
                                <span v-if="!$v.formResponses.price.decimal" class="help-block">Musí být číslo!</span>
                                <span v-if="!$v.formResponses.price.minValue" class="help-block">Číslo musí být kladné!</span>
                            </div>
                        </div>      
                    </div>
                </form>
            </div>
            <div class="box box-primary">
                <div class="box-footer">
                    <div  @mouseenter="$v.formResponses.$touch()">
                        <button @click="submitForm" type="button" class="btn btn-success pull-left" :disabled="$v.formResponses.$invalid" style="margin-right: 10px">Přidat</button>
                    </div>
                    <div class="checkbox pull-left">
                        <label>
                            <input v-model="dontClosePage" type="checkbox"> Budu přidávat dál                       
                        </label>
                    </div>
                    <button type="button" class="btn btn-default pull-right" @click="closeTab(page.id)">Zrušit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const name = 'OperationAdd';

// Vuelidate
import { required, maxLength, requiredIf, decimal, minValue } from 'vuelidate/lib/validators'

// Select2
import Select2 from '../components/pageux/Select2';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    components: { 
        Select2,
    },
    data() {
        return {
            page: null,
            dontClosePage: false,

            errors: [],
            success: false,
            canlog: false,
            admin: false,

            formResponses: {
                type: 'Práce',
                description: '',
                dph: "21%",
                invoice_price: 0,
                amount: 0,
                price: 0
            }
        }
    },
    validations: {
        formResponses: {
            description: {
                required,
                maxLength: maxLength(64)
            },
            invoice_price: {
                required,
                decimal,
                minValue: minValue(1)
            },
            price: {
                required: requiredIf(function () {
                    return this.formResponses.type === "Práce" ? 0 : 1
                }),
                decimal,
                minValue: minValue(0)
            },
            amount: {
                required,
                decimal,
                minValue: minValue(0)
            }
        }
    },
    computed: mapGetters(["getAllPages", 'minCustomers', 'invoiceInfo']),
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "removeTab", 'closeTab', "fetchInvoiceInfo", "addListOperation", 'makeCurrentListOperation']),
        async submitForm()
        {            
            if (this.$v.formResponses.$invalid) 
            {
                return 0;
            }

            this.errors = [];
            
            const values = {
                type: this.formResponses.type,
                description: this.formResponses.description,
                dph: this.formResponses.dph,
                invoice_price: this.formResponses.invoice_price,
                amount: this.formResponses.amount,
                price: this.formResponses.price
            }

            const res = await this.addListOperation(values);

            if (res.code == "LIST_OPERATION_ADDED")
            {
                this.$alerts.toast('Záznam byl úspěšně vytvořen.', 'success');

                if (this.dontClosePage)
                {
                    // Clear data 
                    this.formResponses.description = "";
                    this.formResponses.invoice_price = "";
                    this.formResponses.amount = "";
                    this.formResponses.price = "";
                    this.$nextTick(() => { this.$v.$reset() })
                }
                else
                {
                    this.closeTab(this.page.id);
                    //this.makeCurrentListOperation(res.data.id);
                }
            }
            else
            {
                this.$alerts.message('Došlo k chybě při vytváření záznamu.', "", 'error');
            }
        }
    },
    async created()
    {
        this.fetchInvoiceInfo().then(() => {
            if(!this.invoiceInfo.taxed) {
                this.formResponses.dph = "0%"
            }
        })
        this.page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>

<style scoped>
    .box-body .col-md-12:not(.no-remove) {
        padding: 0;
        margin: 0 0 10px 0;
    }

    .box {
        margin-bottom: 10px;
    }
</style>
